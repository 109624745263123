import { MutableRefObject, useRef } from 'react';
import { useState } from 'react';
//import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';
import { config } from '../../libs/config';
import { ToastContainer, toast } from 'react-toastify';
import { FormInput } from '../../libs/forms/formsInput';
import { InputsForm, TextAreaForm } from './contact';
import { FormTextarea } from '../../libs/forms/formsTextArea';
import 'react-toastify/dist/ReactToastify.css';
import './contact.scss';

const EmailForm = () => {
	const form: MutableRefObject<HTMLFormElement | null> = useRef(null);
	const [values, setValues]: any = useState({
		firstName: '',
		lastName: '',
		email: '',
		copyFromMessage: false,
		message: '',
	});

	const inputFileds: InputsForm[] = [
		{
			id: 1,
			name: 'firstName',
			type: 'text',
			placeholder: 'First Name',
			errorMessage:
				"Username should be 3-16 characters and shouldn't include any special character!",
			label: 'First Name',
			pattern: '^[A-Za-z]{3,56}$',
			required: true,
		},
		{
			id: 2,
			name: 'lastName',
			type: 'text',
			placeholder: 'Last Name',
			errorMessage:
				"Username should be 3-16 characters and shouldn't include any special character!",
			label: 'Last Name',
			pattern: '^[A-Za-z]{3,56}$',
			required: true,
		},
		{
			id: 3,
			name: 'email',
			type: 'email',
			placeholder: 'Email',
			errorMessage: 'It should be a valid email address!',
			label: 'Email',
			required: true,
		},
		{
			id: 4,
			name: 'messageCopy',
			type: 'checkbox',
			label: 'Send a copy from the Message?',
		},
	];

	const textareas: TextAreaForm[] = [
		{
			id: 5,
			name: 'message',
			placeholder: 'Your message',
			label: 'Message',
			rows: 10,
			required: true,
		},
	];

	const handleSubmitTest = (e: any) => {
		e.preventDefault();
		
		if (form.current) {
			emailjs
				.sendForm(
					config.mail.serviceId,
					config.mail.templateId,
					form.current,
					config.mail.userId
				)
				.then(
					(result) => {
						toast.success('Email sent successfully', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored',
						});

						setValues({
							firstName: '',
							lastName: '',
							email: '',
							copyFromMessage: false,
							message: '',
						});
					},
					(error) => {
						toast.error('Ooops, something went wrong', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'colored',
						});
					}
				);
			form.current.reset();
		}

		e.target.reset();
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValues({
			...values,
			[e.target.name]:
				e.target.type === 'checkbox'
					? e.target.checked
					: e.target.value,
		});
	};

	return (
		<div className='contact'>
			<form ref={form} onSubmit={handleSubmitTest}>
				<h1>Contact Form</h1>
				{inputFileds.map((inputField) => (
					<FormInput
						key={inputField.id}
						{...inputField}
						type={inputField.type}
						value={values[inputField.name]}
						onChange={onChange}
					/>
				))}
				{textareas.map((textarea) => (
					<FormTextarea
						key={textarea.id}
						{...textarea}
						value={values[textarea.name]}
						onChange={onChange}
					/>
				))}
				<button>Submit</button>
			</form>
			<ToastContainer />
		</div>
	);
};

export { EmailForm };
