import { AppConfig } from './config';
import { Constraction } from '../../pages/constraction';
import { Contact } from '../../pages/contact';
import { overMe } from '../../pages/me';
import { Impresum } from '../../pages/impresum';
import { Kalender } from '../../pages/kalender';
import { Team } from '../../pages/team';
import { ErrorPage } from '../../pages/error';
import { Home } from '../../pages/home';
import { DataProtection } from '../../pages/dataProtection';
import { Sponsors } from '../../pages/sponsors';
import { Prices } from '../../pages/timesAndEat';

const config: AppConfig = {
    general: {
        mode: 'development',
        appDir: '',
        logDir: '',
    },
    navigation: {
        error: { name: 'Error Page', component: ErrorPage },
        home: { name: "Zwergerl Stub'n", component: Home },
        links: [
            { name: 'Über Mich', component: overMe },
            { name: 'Pädagogisches Konzept', component: Constraction },
            { name: 'kleine Gruppe', component: Constraction },
            { name: 'Kosten', component: Prices },
            { name: 'Kontakt', component: Contact },
        ],
        foobar: [
            { name: 'Impressum', component: Impresum },
            { name: 'Kontakt', component: Contact },
            { name: 'Datenschutz', component: DataProtection },
        ],
    },
    database: {
        protokoll: 'mongodb+srv://',
        url: '',
        user: '',
        pw: '',
    },
    mail: {
        serviceId: 'service_s9j829q',
        templateId: 'template_pc01vfp',
        userId: 'lPV8dkdOY762IBxcT',
    },
    series: [
        {
            name: 'Virtual Endurance Championchip',
            url: 'https://https://simracing.club',
            calenderUrl: 'https://simracing.club/vec-schedule/',
            calenderType: 'VECal',
        },
        {
            name: 'P1 Gaming e.V.',
            url: 'https://p1-gaming.de',
            calenderUrl: 'https://p1-gaming.de/index.php?pages/p1lmscalendar/',
            calenderType: 'HTMLTable',
        },
    ],
};

export { config };
