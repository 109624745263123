import { EmailForm } from '../../components/contact';

const Contact = () => {
	return (
		<div className='module'>
			<EmailForm />
		</div>
	);
};

export { Contact };
