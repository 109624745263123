import { config } from '../../libs/config';
import { NavLink } from 'react-router-dom';
import './foobar.scss';

const Foobar = () => {
	let links: string[] = [];
	config.navigation.foobar.forEach((link) => {
		links.push(link.name);
	});
	return (
		<div className='foobar'>
			<ul>
				{links.map((name, index) => (
					<li key={Math.random()}>
						<NavLink key={index} to={`/${name.toLowerCase()}`}>
							{name}
						</NavLink>
					</li>
				))}
			</ul>
		</div>
	);
};

export { Foobar };
