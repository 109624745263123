import { useState } from 'react';

const FormTextarea = (props: any) => {
	const [focused, setFocused] = useState(false);
	const { label, errorMessage, onChange, id, ...textareaProps } = props;

	const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFocused(true);
	};

	return (
		<div className='formInputText'>
			<label className='formLabelText'></label>
			<textarea
				{...textareaProps}
				onChange={onChange}
				onBlur={handleFocus}
				onFocus={() =>
					textareaProps.name === 'confirmPassword' && setFocused(true)
				}
				focused={focused.toString()}
			>
				{textareaProps.value}
			</textarea>
			<span>{errorMessage}</span>
		</div>
	);
};

export { FormTextarea };
