import { useState } from 'react';

const FormInput = (props: any) => {
	const [focused, setFocused] = useState(false);
	const { label, type, errorMessage, onChange, id, ...inputProps } = props;

	const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFocused(true);
	};

	return (
		<div className='formInputText'>
			<p>{type.toLowerCase() === 'checkbox' ? label : ''}</p>
			<label
				className={
					type.toLowerCase() === 'checkbox'
						? 'formLabelSwitch'
						: 'formLabelText'
				}
			>
				<input
					{...inputProps}
					type={type.toLowerCase()}
					onChange={onChange}
					onBlur={handleFocus}
					onFocus={() =>
						inputProps.name === 'confirmPassword' &&
						setFocused(true)
					}
					focused={focused.toString()}
				/>
				<span
					className={
						type.toLowerCase() === 'checkbox'
							? 'slider'
							: 'errorMessage'
					}
				>
					{errorMessage}
				</span>
			</label>
		</div>
	);
};

export { FormInput };
