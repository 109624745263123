import { useState, useEffect } from 'react';

const CurrentScreenSize = (choose: 'width' | 'height'): number => {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    let width = windowSize[0];
    let height = windowSize[1];
    let both = [width, height];

    let result = choose === 'width' ? width : choose === 'height' ? height : 0

    return result;
}

export { CurrentScreenSize }