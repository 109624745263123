import { config } from '../libs/config';
import { Navbar } from '../components/navbar';
import { Route, Routes } from 'react-router-dom';
import { Foobar } from '../components/foobar';

const CreateLinkRoutes = () => {
	return (
		<Routes>
			{/*Error Route*/}
			<Route
				key={Math.random()}
				path='*'
				Component={config.navigation.error.component}
			/>

			{/*Home Route*/}
			<Route
				key={Math.random()}
				path='/'
				Component={config.navigation.home.component}
			/>

			{/*Navigation Routes*/}
			{config.navigation.links.map((link, index) => (
				<Route
					key={index}
					path={
						link.name.toLowerCase() === 'home'
							? '/'
							: link.name.toLowerCase()
					}
					Component={link.component}
				/>
			))}

			{/*Foobar Routes*/}
			{config.navigation.foobar.map((link, index) => (
				<Route
					key={index}
					path={link.name.toLowerCase()}
					Component={link.component}
				/>
			))}
		</Routes>
	);
};
const App = () => {
	return (
		<div className='main'>
			<Navbar />
			<CreateLinkRoutes />
			<Foobar />
		</div>
	);
};

export { App };
