import { useState, useEffect } from 'react';
import { Divide as Hamburger } from 'hamburger-react';
import { config } from '../../libs/config';
import { Link, NavLink } from 'react-router-dom';
import './navbar.scss';
import { ListProps } from './navbar';

const ReadLinks = (param: ListProps) => {
	let links: string[] = [];
	config.navigation.links.forEach((link) => {
		links.push(link.name);
	});

	return (
		<ul className={param.open ? 'open' : ''} key={Math.random()}>
			{links.map((name, index) => (
				<li key={Math.random()}>
					<NavLink
						className='items'
						key={index}
						to={`/${name.toLowerCase()}`}
					>
						{name}
					</NavLink>
				</li>
			))}
		</ul>
	);
};

const Navbar = () => {
	const [isOpen, setOpen] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const changeWidth = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', changeWidth);

		return () => {
			window.removeEventListener('resize', changeWidth);
		};
	}, []);

	return (
		<nav>
			<Link className='title' to={`/`}>
				{config.navigation.home.name}
			</Link>
			<div className='hamburger-div'>
				<Hamburger
					color='rgb(0, 168, 129)'
					toggled={isOpen}
					size={40}
					rounded
					toggle={setOpen}
				/>
			</div>
			{(isOpen || screenWidth > 500) && (
				<ReadLinks key={Math.random()} open={isOpen} />
			)}
		</nav>
	);
};

export { Navbar };
