import React from 'react';
import './prices.scss';
import { Link } from 'react-router-dom';
const Prices = () => {
	return (
		<div className='module'>
			<div className='prices'>
				<h1>
					Übersicht angebotener Stunden:
				</h1>
				<table>
					<thead>
						<tr>
							<th>
								Betreuungsstunden pro Woche
							</th>
							<th>
								Buchungszeitkategorie
							</th>
							<th>Elternbeitrag monatl. pro Kind</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td data-title="Betreuungsstunden pro Woche">mehr als 25 bis 30</td>
							<td data-title="Buchungszeitkategorie">5-6 Stunden</td>
							<td data-title="Elernbeitrag monatl. pro Kind">291,00 €</td>
						</tr>

						<tr>
							<td data-title="Betreuungsstunden pro Woche">mehr als 30 bis 35</td>
							<td data-title="Buchungszeitkategorie">6-7 Stunden</td>
							<td data-title="Elernbeitrag monatl. pro Kind">340,00 €</td>
						</tr>
						
						<tr>
							<td data-title="Betreuungsstunden pro Woche">mehr als 35 bis 40</td>
							<td data-title="Buchungszeitkategorie">7-8 Stunden</td>
							<td data-title="Elernbeitrag monatl. pro Kind">388,00 €</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export { Prices };
