import React from 'react';
import pictureOverMe from '../../images/members/tanja.jpeg'
import './overMe.scss'
const overMe = () => {
	return (
		<div className='module'>
			<div className='overMe'>
				<img src={pictureOverMe} alt='picture' />

				<h1>Über mich</h1>

				<p>Liebe Eltern,</p>
				<p>mein Name ist Tanja Conrads, ich bin am 07.03.1988 in München geboren und bin mit meinem Mann (Ludwig Conrads) verheiratet.</p>
				<p>Dank meiner 3 Kinder im Teenie- und Kleinkindalter habe ich viel Erfahrung im Umgang mit ihnen.</p>

				<p>Meine Ausbildung absolvierte ich als Zahnmedizinische Fachangestellt und bildete mich dort steht's fort.
					Jetzt nach unserem Umzug von München raus nach Geltendorf suche ich eine neue Herrausforderung und möchte Familie und Job besser vereinen.
					Da mir Kinder sehr am Herzen liegen, lag es nah, in dem Bereich neu durchzustarten. <br />
					Momentan absolviere ich die Grundqualifizierung zur Kindertagespflegeperson.</p>
				
				<p>Mit eben dieser werde ich ab Juni 2024 mit bis zu 5 Kindern im alter von ein bis drei Jahren starten.</p>
			</div>
		</div>
	);
};

export { overMe };
