import { config } from '../../libs/config';
import logo from '../../images/Logos-orginal/1.png';
import './Constraction.scss';
import { ImageSlider } from '../../components/home/imageSlider';

const LogoSlide = () => {
	return (
		<a
			className='App-link'
			href='https://conrads.ipv64.de'
			target='_blank'
			rel='noopener noreferrer'
		>
			<img
				src={logo}
				className='App-logo'
				alt='logo'
				key={Math.random()}
			/>
		</a>
	);
};

const Constraction = () => {
	return (
		<div className='module' key={Math.random()}>
			<div className='App-header' key={Math.random()}>
				<LogoSlide />

				<p key={Math.random()}>
					is under production
				</p>
			</div>
		</div>
	);
};

export { Constraction };
