import './impresum.scss';

const Impresum = () => {
	return (
		<div className='module'>
			<div className='impresum'>
				<h1>Impressum</h1>

				<h2>Angaben gemäß § 5 TMG</h2>
				<p>
					Ludwig Conrads
					<br />
					Rosenstr. 11
					<br />
					82269 Geltendorf
				</p>

				<h2>Kontakt</h2>
				<p>
					Telefon: <a href='tel:+49 151 11546610'>+49 151 11546610</a>
					<br />
					E-Mail:{' '}
					<a href='mailto: ludi@conrads-web.de'>
						ludi@conrads-web.de
					</a>
				</p>

				<p>
					Quelle: <a href='https://www.e-recht24.de'>e-recht24.de</a>
				</p>
			</div>
		</div>
	);
};

export { Impresum };
