import { ImageSlider } from '../../components/home/imageSlider';
import logo from '../../images/Logos-orginal/1.png';
import './home.scss';

const Logo = () => {
	return (
		<a
			className='App-link'
			href='https://conrads.ipv64.de'
			rel='noopener noreferrer'
		>
			<img src={logo} className='logo' alt='logo' />
		</a>
	);
};

const Home = () => {
	return (
		<div className='module'>
			<div className='home'>
				<ImageSlider />
				<div className='info'>
					<Logo />
					<div className='text'>
						<h2>
							Aktuell sind noch 5 Plätze verfügbar
						</h2>
						<p>
							Hierzlich Willkommen bei meiner Kindertagespflege Zwergerl Stub`n
						</p>
						<p>
							Meine Kindertagespflege öffnet ab September 2024 und hat die Möglichkeit 5 Kinder im Alter von ein bis drei Jahren zu betreuen.
							In den kleinen Gruppen kann Ihr Kind individuell und sehr familär betreut werden. 
						</p>
						<p>
							Meine Räume sind Kindergerecht ausgestattet und bieten viel Platz zum entdecken und entfalten.
						</p>
						<p>
							Die Öffnungszeiten werden von Montag bis Freitag 07:30 - 16:00 Uhr sein.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Home };
