import SimpleImageSlider from 'react-simple-image-slider';
import image1 from '../../images/slider/orginal/Mia-Hochzeit.png';
import image2 from '../../images/slider/orginal/Conrads.png';
import image3 from '../../images/slider/orginal/mia-arthur.png';
import image4 from '../../images/slider/orginal/snack.png';
import image5 from '../../images/slider/orginal/tanja.png';
import { CurrentScreenSize } from '../../libs/screen';

const images = [
	{ url: image1 },
	{ url: image2},
	{ url: image3 },
	{ url: image4 },
	{ url: image5 },
];

const ImageSlider = () => {
	return (
		<>
			<SimpleImageSlider
				width={
					CurrentScreenSize('width') > 500
						? CurrentScreenSize('width') - 15
						: CurrentScreenSize('width')
				}
				height={CurrentScreenSize('height') / 2}
				images={images}
				showBullets={true}
				showNavs={true}
				autoPlay={true}
				useGPURender={true}
				loop={true}
				bgColor='#fff'
			/>
		</>
	);
};

export { ImageSlider };
